
.black {
  background-color: black;
}

.carousel-item {
height: 300px;
overflow: hidden;
}
.carousel-item img {
width: 100%;
}

.bio-text {
  font-size:18px;
}


@media screen and (min-width: 800px) {
  .carousel-item{
    height: 600px;
  }
}

