.detail-section {
  display: flex;
  flex-direction: row;
}

#post-detail-header {
  /* text-align: center; */
  color: black;
  font-size: 72px;
  /* padding: 1rem; */
}

.sub-header {
  font-style: italic;
  font-size: 14px;
  padding: 15px 0;
}

#post-detail-header h1 {
  font-size: 2rem;
  font-family: "Quicksand", sans-serif;
}



.detail-img-container {
  width: 750px;
  /* margin:auto; */

}

#post-detail-img {
  width: 100%;
}


#detail-text-container {
  color: black;
  background: white;
  padding: 1rem;
  width: 750px;
  font-size: large;
  /* margin: auto; */

} 
ul {
  /* background: white; */
  width: 100%;
}

#detail-text-container a {
  color: #242424;
  font-weight: 800;
}
#detail-text-container a:hover {
  font-style: italic;
  color: tomato;
}

#detail-text-container a:active {
  font-style: italic;
  color: tomato;
}

#detail-text-container p {
  overflow-y: scroll;
}

#blog-detail-back-btn {
  cursor: pointer;
  color: white;
  font-weight: 800;
  margin: auto;
  padding: 4px;
  margin-bottom: 2rem;
  margin-top: 2rem;
  transition: 150ms all;
}

#blog-detail-back-btn:hover {
  color: orangered;
}

@media screen and (max-width: 1100px) {
  #post-detail-header h1 {
    font-size: 2rem;
    padding-top: 1rem;
  }

  .detail-img-container {
    max-width: 100%;
    margin:auto;
  
  }

  .post-detail-text p {
    width: 100%;
    text-align: left;
  }

  #post-detail-text {
    width: 100%;
  
  }

  .detail-section {
    flex-direction: column-reverse;
  }

  #detail-text-container {
    width: 100%;
  }


}
