.logo-container {
  margin: auto;
  width: fit-content;
  text-align: center;

}

.logo-main {
  width: 40%;
  height: auto;
  margin: auto;
}

.text-white {
  color: white;
}

nav a {
  color: #bfbfbf;
  transition: 300ms ease-in;
  margin-right: 12px;
}

nav a.active {
  font-weight: bold;
  color: white;
}

nav a:hover {
  color: tomato;
  font-weight: bold;
  text-decoration: none;
}

@media screen and (max-width: 1200px){
  .logo-main {
    width: 50%;
   
  }

}