

.ccCover {
  max-width: 75%;
  height: auto;
  margin:auto;
}
.mauto {
  margin:auto;
}

a {
  display: inline;
  color: lightgray;
}

a:hover {
  /* color: #0275d8; */
  color:tomato;
  ;
}

h3 {
  font-family: 'Quicksand', sans-serif;
}