.contactPic {
  width: 100%;
}

 a {
  transition: 200ms ease-in;
}

 a:hover {
  color: tomato;
  text-decoration: none;

}
