.not-found-img {
  max-width: 65%;
  margin:auto;
  margin-top: 40px;
}

#not-found-text {
  text-align: center;
  color:white;
  font-size: larger;
  margin: 2rem;
  font-style:italic;
}

@media screen and (max-width: 1000px) {
  .not-found-img {
    max-width: 90%;
  }
}