.blog-card {
  background-color: rgb(20, 20, 20);
  margin: auto;
  transition: 150ms all;
  height: 275px;
  aspect-ratio: 1/1;
  overflow: hidden;
}

.blog-img {
  transition: all .5s ease 0s;
  width: 100%;
  object-fit: contain;
  filter: brightness(80%);
  margin: auto;
}

.text-area {
  padding: 0 8px;
  position: absolute;
  bottom: 30%;
  height: 45%;
  width: 100%;
  background-color: black;
  opacity: 0;
  transition: all .5s ease 0s;
}
.text-paragraph {
  margin-top: 25px;
  display: none;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-footer {
  position: absolute;
  bottom: 0;
  text-align: right;
}

.blog-card:hover {
  transition: all .5s ease 0s;
  /* border: 1px solid  rgb(190, 190, 190); */
  cursor: pointer;

  .text-area {
    opacity: .9;
  }

  .blog-img {
    filter: brightness(50%);
    opacity: .9;
  }
}

@media screen and (max-width: 760px) {
  .created-on {
    display: none;
  }

  .text-area {
    opacity: 1;
  }

  .text-paragraph {
    margin-top: 0px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .blog-card {
    height:fit-content;
  }

}
