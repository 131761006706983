
.albumCard {
  width: 275px;
  height:275px;
  padding:0px;
  
}

.albumCard:hover {
  border: 1px solid white;

}

.modal-border, .modal-header-border {
  border: .5px solid white;

}


.albumCard:active {
  opacity: .5;
}

.modalColumn{
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.modalCover
 {
width: 100%;
height:auto;
}

.darkColor {
  background-color: #343a40	;
}

.close {
  color: #fff; 
  opacity: 1;
}
.close:hover {
  color: tomato; 
  opacity: 1;
}

ul li {
  list-style: none;
}

.hover:hover{
  color: tomato;
}
.Button:hover{
  color: tomato;
}