.tour-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  color: white;
  padding: 25px 10px;
  margin-top: 1rem;
  background-color: rgb(39, 39, 39);
}

.tour-item-section {
  text-align: left;
  width: 25%;
  padding: 0 10px;
}

.ticket-btn {
  background: none;
  color: white;
  border: 1px solid white;
  padding: 2px;
}

.ticket-btn:active {
  color: red;
  border: 1px solid red;

}

.ticket-btn:hover {
  border: 1px solid tomato;
}

#venue-section {
  width: 30%;
}
#button-section {
  text-align: right;
  width: 15%;
}

.no-posts {
  color: white;
  margin-top: 5rem;
}

@media screen and (max-width: 1100px) {
  .tour-item {
    flex-wrap: wrap;
  }
  .tour-item-section {
    width: 100%;
  }
  #venue-section {
    width: 100%;
  }
  #button-section {
    width: 100%;
    text-align: left;
  }
}
